import FlexBox from '../flex-box';
import Loader from './bounce-loader';

function FullScreenLoader() {
	return (
		<FlexBox
			fullWidth
			id='full-screen-loader'
			sx={{
				position: 'fixed',
				left: 0,
				top: 0,
				height: '100vh',
				width: '100vw',
				zIndex: 10000,
				background: 'rgba(0, 0, 0, .4)',
				overflowY: 'hidden',
			}}
		>
			<Loader />
		</FlexBox>
	);
}

export default FullScreenLoader;
