import FullScreenLoader from '@/atomic-components/loaders/full-screen-loader';
import { ViewStates } from '@/models/generics';
import { ComponentType, useState } from 'react';

export type WithLoaderProps = {
	showLoader: () => void;
	hideLoader: () => void;
};

export function withFullScreenLoader<T extends WithLoaderProps>(
	WrappedComponent: ComponentType<T>
): any {
	const RenderingComponent = (props: any) => {
		const [loaderViewState, setLoaderViewState] = useState<ViewStates>(
			ViewStates.NOT_VISIBLE
		);
		function showLoader() {
			setLoaderViewState(ViewStates.VISIBLE);
		}

		function hideLoader() {
			setLoaderViewState(ViewStates.NOT_VISIBLE);
		}

		return (
			<>
				{loaderViewState == ViewStates.VISIBLE && <FullScreenLoader />}
				<WrappedComponent
					{...(props as T)}
					showLoader={showLoader}
					hideLoader={hideLoader}
				/>
			</>
		);
	};
	return RenderingComponent;
}
