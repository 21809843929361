import { SvgIconProps } from '@/models/generics';

const ArrowIcon = (props: SvgIconProps) => {
	return (
		<svg
			width={props?.width ? props?.width : '24'}
			height={props?.height ? props?.height : '24'}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5 12H19'
				stroke={props?.strokeColor ? props?.strokeColor : 'white'}
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M12 5L19 12L12 19'
				stroke={props?.strokeColor ? props?.strokeColor : 'white'}
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default ArrowIcon;
