import { Button, styled, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import FlexBox from '../atomic-components/flex-box';
import { getColor } from './getColor';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
export const Custom_loader = styled(FlexBox)`
	margin-top: 60%;
	flex-direction: column;
	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ripple div {
		position: absolute;
		border: 4px solid #555555;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	.lds-ripple div:nth-child(2) {
		animation-delay: -0.5s;
	}
	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		4.9% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		5% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}
`;

interface ICustomLoadEProps {
	sx?: SxProps;
	isErrored?: boolean;
}

function Custom_loade(props?: ICustomLoadEProps) {
	const {isVisible} = useFullScreenLoader();
	if(!isVisible) return null
	return (
		<FlexBox sx={{ flexDirection: 'column' }} {...props}>
			<Custom_loader>
				<Box className='lds-ripple'>
					<Box />
					<Box />
				</Box>
			</Custom_loader>
			{props?.isErrored === true && (
				<>
					<Typography sx={{ margin: '16px', color: getColor('Gray 0') }}>
						ERROR OCCURED!!!! Please Try Again
					</Typography>
					<Button
						sx={{
							border: `1px solid ${getColor('Gray -4')}`,
							color: getColor('Gray 0'),
							borderRadius: '24px',
							marginTop: '10%',
							padding: '8px 24px',
						}}
						onClick={() => window?.location.reload()}
					>
						RETRY
					</Button>
				</>
			)}
		</FlexBox>
	);
}

export default Custom_loade;
